
import { defineComponent } from 'vue'
import pageHead from '../components/page-head.vue'
import { fileUpload } from '@/api/base'
import {
    getReportDetail,
    getBillSet,
    updateBill,
    delBill,
    ReportDetailDto,
    FileDto,
    BillDto,
    BillSetDto
} from '@/api/report'
import global from '@/utils/global'
import { ElUploadRequestOptions } from 'element-plus/lib/components/upload/src/upload.type'

interface BillItem {
    id:number
    series:string
    type:string
    url:string
}

export default defineComponent({
    name: 'ReportDetail',
    components: {
        'page-head': pageHead
    },
    data () {
        return {
            data: {} as ReportDetailDto,
            billList: [] as BillItem[][][],
            showPicEdit: false,
            showWarning: false,
            fileList: [] as FileDto[],
            cateList: [] as BillSetDto[],
            activeTab: 0,
            activeCate: 0,
            activeCateName: '',
            fileListMap: {} as {[key:string]:FileDto[]},
            reportId: Number(this.$route.params.id),
            token: global.token,
            showPreview: false,
            previewImg: '',
            simplePreviewFile: ''
        }
    },
    async created () {
        this.getBill()
    },
    methods: {
        handlePreview (i:number, index:number, key:number) {
            const item = this.billList[i][index][key]
            this.activeTab = i
            this.activeCate = index
            this.activeCateName = item.type
            this.fileList = this.fileListMap[this.activeCateName]
            this.previewImg = item.url
            this.showPreview = true
        },
        activeCateItem () {
            const cate = this.cateList[this.activeTab]
            const bills = cate?.bills || []
            return bills[this.activeCate]
        },
        getBill () {
            getBillSet().then((res) => {
                this.cateList = res
                const map:{[key:string]:FileDto[]} = {}
                res.forEach(item => {
                    item.bills.forEach(bill => {
                        map[bill.name] = []
                    })
                })
                this.fileListMap = map
                this.activeCateName = res?.[0]?.bills?.[0].name
                this.getReport()
            })
        },
        async getReport () {
            const result = await getReportDetail({
                reportId: this.$route.params.id as string
            })
            this.data = result
            const level1Map:{[key:string]:number} = {}
            const level2Map:{[key:string]:[number, number]} = {}
            const billsArr:BillItem[][][] = []
            result.bill.forEach((item) => {
                this.fileListMap[item.type] && this.fileListMap[item.type].push({
                    file_name: item.type,
                    external_url: item.url,
                    preview_url: item.url,
                    file_id: item.id + ''
                })
                if (level1Map[item.series] === undefined) {
                    const level1Index = billsArr.length
                    level1Map[item.series] = level1Index
                    level2Map[item.type] = [level1Index, 0]
                    billsArr[level1Index] = [[item]]
                } else {
                    if (level2Map[item.type]) {
                        billsArr[level2Map[item.type][0]][level2Map[item.type][1]].push(item)
                    } else {
                        const level1Index = level1Map[item.series]
                        billsArr[level1Index].push([item])
                        level2Map[item.type] = [level1Index, billsArr[level1Index].length - 1]
                    }
                }
            })
            this.billList = billsArr
        },
        onTab (bills:BillDto[], index:number) {
            this.activeTab = index
            this.activeCateName = bills[0].name
            this.activeCate = 0
            this.fileList = this.fileListMap[bills[0].name]
        },
        onCate (name:string, index:number) {
            this.fileList = this.fileListMap[name]
            this.activeCateName = name
            this.activeCate = index
        },

        async uploadItem (file: ElUploadRequestOptions) {
            const FormDatas = new FormData()
            FormDatas.append('iFile', file.file)
            const res = await fileUpload(FormDatas)
            await updateBill({
                reportId: this.reportId,
                fileId: res.fileInfo.file_id,
                series: this.cateList[this.activeTab].value,
                type: this.activeCateItem().name
            })
            this.fileList.push(res.fileInfo)
            this.fileListMap[this.activeCateName] = this.fileList
        },

        async delPic (id:string, index:number) {
            await delBill({ id: Number(id), reportId: this.reportId })
            const newList = [...this.fileList]
            newList.splice(index, 1)
            this.fileList = newList
            this.fileListMap[this.activeCateName] = newList
        },
        closeDialog () {
            this.fileList = []
            this.activeTab = 0
            this.activeCate = 0
            this.activeCateName = ''
            this.previewImg = ''
            this.showPicEdit = false
        },
        edit () {
            this.showPicEdit = true
            this.showPreview = false
        },
        knowBtnClick () {
            this.showWarning = false
            this.showPicEdit = true
        }
    }
})
